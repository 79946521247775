<template>
    <div class="PromotionTwo">
        <div class="PromotionTwoHeader">促销商品：请选择参加活动的商品</div>
        <div class="PromotionTwoTable">
            <el-table row-key="id" :data="tableData" ref="multipleTable" @selection-change="selectShop" style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC" :cell-style="{height: '100px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}">
                <el-table-column type="selection" width="55" align="center" reserve-selection></el-table-column>
                <el-table-column label="商品名称" prop="name" align="center">
                    <template slot-scope="scope">
                        <div class="shopData">
                            <img :src="scope.row.image.src" alt="">
                            <span><span style="margin-left: 0" class="text-overflow-2">{{scope.row.name}}</span> <b>在售</b></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品价格" prop="price" align="center"></el-table-column>
                <el-table-column label="库存" prop="inventory" align="center"></el-table-column>
            </el-table>
        </div>
<!--        <div class="setSku">-->
<!--            <span>最多可设置50000个SKU（<b>211</b>/50000）且4000个SPU</span>-->
<!--        </div>-->
        <div class="footer">
            <div class="footerLeft">
                <el-button class="btn" @click="toBack">上一步</el-button>
                <el-button class="btn" @click="toNext">提交</el-button>
            </div>
<!--            <el-pagination-->
<!--                    class="operation-pagination"-->
<!--                    :current-page="adminPages.currentPageNum"-->
<!--                    :page-size="adminPages.eachPageNum"-->
<!--                    layout="sizes, prev, pager, next, total, jumper"-->
<!--                    :total="adminPages.total"-->
<!--                    @current-change="adminCurrentChange"-->
<!--            ></el-pagination>-->
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "operationPromotionStorePromotionTwo",
        data(){
            return {
                tableData:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                resData:{},
                selectList:[],
            }
        },
        created() {
            this.getShowStoreMarket();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions(['setDataMenusList']),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getShowStoreMarket(){
                this.$http.axiosGet(this.$api.showStoreMarket, res=>{
                    if(res.code === 200){
                        this.resData = res.data.data[0];
                        this.tableData = res.data.data[0].goods_list;
                        if(this.$route.query.editData){
                            let editData = JSON.parse(this.$route.query.editData);
                            if(editData){
                                let list = editData.activity_data.data;
                                list.map(item=>{
                                    if(item.goods_data){
                                        this.selectList.push(item.goods_data)
                                    }
                                })
                                this.selectList.forEach(row=>{
                                    this.$refs.multipleTable.toggleRowSelection(row)
                                })
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectShop(val){
                this.selectList = val;
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                // this.getComList()
            },
            toBack(){
                this.$router.go(-1)
            },
            toNext(){
                if(!this.selectList.length){
                    this.$message.warning('请至少选择一个商品!');
                    return
                }
                this.selectList.map(item=>{
                    delete item.create_time;
                    delete item.delete_time;
                    delete item.image.src;
                })
                if(this.$route.query.activity_detail && this.$route.query.rule && this.$route.query.name){
                    let activityData = {
                        type:1,
                        data:[]
                    }
                    this.selectList.map(item=>{
                        let goodsData = {
                            goods_data:{
                                id:item.id,
                                name:item.name,
                                image: item.image.url ? item.image.url : item.image,
                                price:item.price,
                                inventory:item.inventory
                            }
                        };
                        activityData.data.push(goodsData)
                    })
                    let list = this.$route.query.rule;
                    list.map(item=>{
                        let obj = {
                            rule:item
                        }
                        activityData.data.push(obj)
                    })
                    let activity_data = JSON.stringify(activityData);
                    let formData = new FormData();
                    formData.append('type','1');
                    formData.append('name',this.$route.query.name);
                    formData.append('activity_detail',this.$route.query.activity_detail)
                    formData.append('activity_data',activity_data);
                    this.$http.axiosPost(this.$api.saveStudentMarket, formData, res=>{
                        if(res.code === 200){
                            if (Number(this.$lockr.get('competition_type')) === 1) {
                                let menus = this.menus;
                                if (Number(menus[1].children[1].status) !== 2) {
                                    this.operation_updateStatus(1,1,2);
                                }
                            }
                            this.$message({
                                type:'success',
                                message:'提交成功！',
                                duration:1000,
                                onClose:()=>{
                                    this.$router.push({
                                        path: '/student/operationpromotion/storepromotion'
                                    })
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } else if(this.$route.query.ruleObj && this.$route.query.name){
                    let ruleObj = this.$route.query.ruleObj;
                    let activityData = {
                        type:2,
                        data:[]
                    }
                    this.selectList.map(item=>{
                        let goodsData = {
                            goods_data:{
                                id:item.id,
                                name:item.name,
                                image:item.image.url,
                                price:item.price,
                                inventory:item.inventory
                            }
                        };
                        activityData.data.push(goodsData)
                    })
                    let obj = {
                        rule:{
                            sum:ruleObj.sum,
                            goods_data:{
                                id:ruleObj.goods_data.id,
                                name:ruleObj.goods_data.name,
                                image:ruleObj.goods_data.image,
                                price:ruleObj.goods_data.price,
                                inventory:ruleObj.goods_data.inventory,
                            }
                        }
                    }
                    activityData.data.push(obj);
                    let activity_data = JSON.stringify(activityData);
                    let str = `满${ruleObj.sum}元赠送${ruleObj.goods_data.name}`;
                    let formData = new FormData();
                    formData.append('type','2');
                    formData.append('name',this.$route.query.name);
                    formData.append('activity_detail',str);
                    formData.append('activity_data',activity_data);
                    this.$http.axiosPost(this.$api.saveStudentMarket, formData, res=>{
                        if(res.code === 200){
                            if (this.$common.isCompetion()) {
                                let menus = this.menus;
                                if (Number(menus[1].children[1].status) !== 2) {
                                    this.operation_updateStatus(1,1,2);
                                }
                            }
                            this.$message({
                                type:'success',
                                message:'提交成功！',
                                duration:1000,
                                onClose:()=>{
                                    this.$router.push({
                                        path: '/student/operationpromotion/storepromotion'
                                    })
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .footerLeft{
        /deep/.el-button--default:hover{
            border-color: #FD4446;
            color: #FD4446;
            background: #ffffff;
        }
    }
    .PromotionTwo{
        padding: 0 20px 20px 20px;
        .PromotionTwoHeader{
            color: #343434;
            font-size: 18px;
        }
        .PromotionTwoTable{
            .shopData{
                display: flex;
                align-items: center;
                /*justify-content: center;*/
                img{
                    width: 48px;
                    height: 48px;
                }
                span{
                    text-align:left;
                    color: #333333;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    b{
                        font-weight: 500;
                        display: inline-block;
                        width: 30px;
                        border: 1px solid #39CD7C;
                        color: #39CD7C;
                        font-size: 10px;
                        /*padding: 3px 6px;*/
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
            }
        }
        .setSku{
            padding-top: 60px;
            color: #343434;
            font-size: 16px;
            b{
                font-weight: 500;
                color: #FD4446;
            }
        }
        .footer{
            padding-top: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footerLeft{
                .btn{
                    width: 100px;
                    height: 40px;
                    line-height: 0;
                }
                .btn:nth-child(2){
                    background: #FD4446;
                    border-color: #FD4446;
                    color: #ffffff;
                }
            }
        }
    }
</style>